import { phone, strongPassword } from './regex';

function notEmpty(label) {
  return { required: true, message: `请输入${label}`, trigger: 'blur' };
}

export default {
  notEmpty,
  // 手机号码
  phone: [
    notEmpty('手机号码'),
    {
      pattern: phone,
      message: '请输入合法的手机号码',
      trigger: 'blur',
    },
  ],

  // 短信验证码
  verifyCode: [notEmpty('短信验证码'), { min: 6, max: 6, message: '请输入合法的短信验证码', trigger: 'blur' }],

  // 图片验证码
  imgCode: [notEmpty('图片验证码'), { min: 4, max: 4, message: '请输入合法的图片验证码', trigger: 'blur' }],

  // 强壮的密码
  strongPassword: {
    pattern: strongPassword,
    message: '请输入8-20位的数字和字母',
    trigger: 'blur',
  },
};
