<template>
  <div class="bp">
    <div class="bp-box">
      <p class="bp-box__title">寻求合作，提交BP</p>
      <el-form :rules="rules" :model="form" label-position="left" label-width="120px" ref="form">
        <el-form-item label="BP名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
          <el-input v-model="form.linkMan" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="上传附件" :required="true">
          <div style="text-align: left">
            <file-import
              ref="fileImport"
              label="点击上传"
              :beforeHandler="onBeforeHandler"
              :afterUploadHandler="onImportHandler"
              :removeHandler="onRemoveHandler"
              accept=".doc,.docx,.ppt,.pptx,.pdf"
            ></file-import>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="yi-btn bp-btn" @click="submit">提交</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// import { dayjs } from 'dayjs';
import validator from '@/utils/validator';
import FileImport from '@/components/FileImport.vue';
export default {
  components: { FileImport },
  data() {
    return {
      form: {},
      rules: {
        name: [{ required: true, message: '请输入BP名称', trigger: 'blur' }],
        linkMan: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [{ required: true, validator: validator.phone, message: '请输入手机号码', trigger: 'blur' }],
      },
    };
  },
  methods: {
    async submit() {
      await this.$refs.form.validate();
      if (!this.form.attachmentList || !this.form.attachmentList.length) return this.$message.warning('请上传附件');
      // this.form.deliveryTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
      await this.$api.businessPlanAddBusinessPlan(this.form);
      this.$message.success('提交成功');
      this.$refs.form.resetFields();
      this.$refs.fileImport.clearFiles();
    },
    onImportHandler(file) {
      if (!this.form.attachmentList) {
        this.$set(this.form, 'attachmentList', []);
      }
      this.$set(this.form.attachmentList, this.form.attachmentList.length, file);
    },
    onRemoveHandler(file) {
      let idx = (this.form.attachmentList || []).findIndex((f) => f.uid === file.uid);
      if (idx > -1) this.$delete(this.form, idx);
    },
    onBeforeHandler(file) {
      const FILE_NAME = file.name || '';
      const fileExt = FILE_NAME.substring(FILE_NAME.lastIndexOf('.'));
      if (!['.doc', '.docx', '.ppt', '.pptx', '.pdf'].includes(fileExt)) {
        this.$message.warning('仅支持doc/docx/ppt/pptx/pdf文件');
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.bp {
  height: 980px;
  padding-top: 180px;
  background-image: url('~@images/map/bg.jpg');
  position: relative;
  &::after {
    position: absolute;
    z-index: 1;
    content: '';
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.25);
  }
  &-box {
    position: relative;
    z-index: 2;
    width: 700px;
    padding: 14px 60px 14px 40px;
    margin: 0 auto;
    border-radius: 8px;
    background: white;
    box-shadow: 0px 0px 10px #0000003d;
    &__title {
      font-size: 32px;
      font-weight: bold;
      text-align: left;
      line-height: 2.5;
      border-bottom: 2px solid #eee;
      margin-bottom: 20px;
    }
    ::v-deep .el-form-item__label {
      font-size: 16px;
    }
    .bp-btn {
      margin-top: 30px;
      width: 340px;
      border-radius: 5px;
      background-color: #bd192f;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 10px 0;
      cursor: pointer;
    }
  }
}

.mobile {
  .bp {
    height: 585px;
    padding: 90px 14px 10px;
    &-box {
      width: 100%;
      padding: 14px;
      ::v-deep .el-form-item__label {
        font-size: 14px !important;
        width: 80px !important;
      }
      ::v-deep .el-form-item__content {
        margin-left: 80px !important;
      }
      .bp-btn {
        width: 234px;
      }
      .bp-box-title {
        font-size: 24px;
        font-weight: bold;
        text-align: left;
        line-height: 1.5;
        padding-bottom: 10px;
      }
    }
  }
}
</style>
