// 手机号码
export const phone = /^1\d{10}$/;

// 汉字
export const chinese = /^[\u4e00-\u9fa5]+$/;

// 非汉字
export const notChinese = /[\u4e00-\u9fa5]+/;

// 8-20位数字+字母 的密码
export const strongPassword = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/;
