<template>
  <el-upload
    ref="uploader"
    action="/advc-portal-api/file/upload"
    :show-file-list="true"
    :before-upload="onBeforeUpload"
    :http-request="handleUpload"
    :on-remove="handleRemove"
    :accept="accept"
  >
    <el-button :loading="loading">
      {{ label }}
    </el-button>
  </el-upload>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '导入',
    },
    beforeHandler: {
      type: Function,
    },
    afterUploadHandler: {
      type: Function,
    },
    removeHandler: {
      type: Function,
    },
    accept: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onBeforeUpload(file) {
      return this.beforeHandler && this.beforeHandler(file);
    },
    handleRemove(file) {
      this.removeHandler && this.removeHandler(file);
    },
    clearFiles() {
      this.$refs.uploader.clearFiles();
    },
    async handleUpload(e) {
      try {
        if (!e.file) return;
        this.loading = true;
        this.$emit('loading', true);
        let formData = new FormData();
        formData.append('file', e.file);
        let res = await this.$api.fileUpload(formData);
        let successTips = this.afterUploadHandler && (await this.afterUploadHandler({ uid: e.file.uid, ...res }));
        this.$notify.success({
          title: '成功',
          message: successTips || '文件上传成功',
        });
      } catch (e) {
        console.log(e);
        this.$notify.error({
          title: '错误',
          message: '文件上传出现错误, 请重新上传',
        });
      } finally {
        setTimeout(() => {
          this.loading = false;
          this.$emit('loading', false);
        }, 100);
      }
    },
  },
};
</script>
